<template>
 <div>
  <h3 class="text-lg font-medium leading-6 text-gray-900">
   {{ $route.name.includes("Pbx") ? "PBX Dashboard" : "Trunk Dashboard" }}
  </h3>
  <dl :class="`mt-5 grid grid-cols-1 gap-5 sm:grid-cols-${basicData.length}`">
   <div
    v-for="item in basicData"
    :key="item.name"
    class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
   >
    <dt class="truncate text-sm font-medium text-gray-500">{{ $t(item.name) }}</dt>
    <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
     {{ item.stat && item.stat >= 0 ? item.stat : 0 }}

     <span v-if="item.name !== 'calls' && item.stat && item.stat >= 0">
      ({{ getPercentageValue(totalCalls, item.stat) }}%)
     </span>
    </dd>
   </div>
  </dl>
 </div>
 <div>
  <dl :class="`mt-5 grid grid-cols-1 gap-5 sm:grid-cols-${durationData.length}`">
   <div
    v-for="item in durationData"
    :key="item.name"
    class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
   >
    <dt class="truncate text-sm font-medium text-gray-500">
     {{ $t(item.name) }}
    </dt>
    <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
     {{ item.stat && item.stat >= 0 ? formatSeconds((item.stat / totalCalls).toFixed(0)) : 0 }}
    </dd>
   </div>
  </dl>
 </div>
 <div>
  <dl :class="`mt-5 grid grid-cols-1 gap-5 sm:grid-cols-${answeredInData.length}`">
   <div
    v-for="item in answeredInData"
    :key="item.name"
    class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
   >
    <dt class="truncate text-sm font-medium text-gray-500">
     {{
      $tc(item.name.split("_")[0], item.name.split("_")[1] ? 2 : 1, {
       seconds: item.name.split("_")[1],
      })
     }}
    </dt>
    <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
     {{ item.stat && item.stat >= 0 ? item.stat : 0 }}
    </dd>
   </div>
  </dl>
 </div>
 <div>
  <dl :class="`mt-5 grid grid-cols-1 gap-5 sm:grid-cols-${talkingInData.length}`">
   <div
    v-for="item in talkingInData"
    :key="item.name"
    class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
   >
    <dt class="truncate text-sm font-medium text-gray-500">
     {{
      $tc(item.name.split("_")[0], item.name.split("_")[1] ? 2 : 1, {
       seconds: item.name.split("_")[1],
      })
     }}
    </dt>
    <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
     {{ item.stat && item.stat >= 0 ? item.stat : 0 }}
    </dd>
   </div>
  </dl>
 </div>
</template>

<script>
export default {
 props: ["basicData", "durationData", "answeredInData", "talkingInData", "totalCalls"],
 methods: {
  formatSeconds(value) {
   var minutes = Math.floor(value / 60);
   var seconds = value - minutes * 60;
   return minutes + "(min)" + ":" + seconds + "(s)";
  },
  getPercentageValue(total, value) {
   return ((parseInt(value) / parseInt(total)) * 100).toFixed(0);
  },
  getAverageTime(time, calls) {
   let avg = undefined;
   if (calls > 0) {
    avg = time / calls;
    if (avg > 0) {
     avg = this.timeFormater(avg);
    }
   } else {
    avg = 0;
   }
   return avg;
  },
 },
};
</script>

<style></style>
